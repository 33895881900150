import React from "react";
import rec from "../../../../assets/rec.mp4";
import { Link } from "react-router-dom";
const Experience = () => {
    return(
         <div className="experience-bck">
                <div className="experience">
            <div className="experience-video">
            <iframe width="315" height="560"
src="https://www.youtube.com/embed/f8wN1tFTKJw"
title="YouTube video player"
frameborder="0"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
allowfullscreen></iframe>
            </div>
            <div></div>
            <div className="experience-content">
                <span>Curated from Dingle, Ireland</span>
                <h1>Experience Ireland from Home</h1>
                <p>MyIrelandBox offers a unique way to learn, taste, and experience Ireland and its vibrant culture from the comfort of your home.</p>
                <div className="experience-btn">
                    <Link to={window.location.href.includes("christmas-ecard") ? "/gifting-form" :"/form"} className="experience-btn1">{window.location.href.includes("christmas-ecard") ? "Gift a MyIrelandBox" :"Get Started"}</Link>
                    <Link to="/peek-inside" className="experience-btn2">See Past Boxes</Link>
                </div>
            </div>
        </div>
         </div>
    )
}

export default  Experience;