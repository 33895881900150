import React from "react";
import Footer from "../../Footer";
import Navbar from "../../Navbar";
import MeetFounder from "./MeetFounder/MeetFounder";
import JoinFacebookGroup from "./JoinFacebookGroup/JoinFacebookGroup";
import TrustBoxSlider from "../Form/TrustBoxSlider";
import Zoom from "react-reveal/Zoom"; // Importing Zoom effect

// CSS
import "../Styles/plans.css";

// IMAGES

import Carousel from "./PlansCarousel/Carousel";

import "../Plans/PlansCarousel/plansCarousel.scss";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

function GiftingPlans({ posts, form, pastBoxes, plans }) {
  var items = [];
  for (var i = 0; i < pastBoxes.length; i++) {
    items.push(pastBoxes[i]);
  }


  const goToCart = (productId) => {
  
    window.open(
      `https://checkout.myirelandbox.com/checkout/buy/${productId}`,
      "_self"
    );
  };
  return (
    <>
      <Navbar posts={posts} />

      <h1
        className="plans-title"
        dangerouslySetInnerHTML={{ __html: plans.acf.planfirst.mainheading }}
      ></h1>

      <h1 className="gifting-plans-link">
        Interested in a MyIrelandBox for yourself? <br></br>
        <Link
          to={window.location.href.indexOf("cw") !== -1 ? "/cw/plans" : "/plans"}
        >
          Please follow link here!
        </Link>
      </h1>
      <div className="products">
        <div className="shop-product-border">
          <div className="shop-product-label">
            <p id="shop-product-label-gifting">Try MyIrelandBox</p>
          </div>

          <div
            className="box-discount"
            onClick={() => {
              goToCart(plans.acf.shop.productid);
            }}
          >
            <div
              className="box box-shop"
              onClick={() => {
                goToCart(plans.acf.shop.productid);
              }}
            >
              <div className="extraDiv">
              {
                plans.acf.shop.planboxBadge ? <img src={plans.acf.shop.planboxBadge} className="ribon" alt="ribon" /> : " "
              }
              
              <div
                className="box-plan-image"
                style={{
                  backgroundImage: `url(${plans.acf.shop.planboximg})`,
                }}
              >
                {plans.acf.shop.shopboxpng ?   <img src={`${plans.acf.shop.shopboxpng}`} className="boxPng" alt="shopbox"/> : " "}
               
              </div>
              <div className="cardText">
                <div
                  className="sub-type"
                  dangerouslySetInnerHTML={{ __html: plans.acf.shop.month }}
                ></div>
                <p
                  className="price-plans"
                  dangerouslySetInnerHTML={{ __html: plans.acf.shop.price }}
                ></p>
                {window.location.href.indexOf("cw") !== -1 ||
                window.location.href.indexOf("gift") !== -1 ? (
                  <div
                    className="extra-plans"
                    dangerouslySetInnerHTML={{
                      __html: plans.acf.shop.offertext,
                    }}
                  ></div>
                ) : (
                  <div
                    className="extra-plans"
                    dangerouslySetInnerHTML={{
                      __html: plans.acf.shop.customtext,
                    }}
                  >
                    {" "}
                  </div>
                )}
                 
                <button
                  className="plans-sub-btn"
                  formTarget="dummyFrame"
                  onClick={() => {
                    goToCart(plans.acf.shop.productid);
                  }}
                >
                  <p
                    dangerouslySetInnerHTML={{
                      __html: plans.acf.shop.subscribebtn,
                    }}
                  ></p>
                </button>
              </div>
              </div>
              {/* <div className="box-notice hide-on-mobile " dangerouslySetInnerHTML={{__html: "<p>Subscription Details</p>",}} data-tip data-for="aMonthySubscription" /> */}

{/* <ReactTooltip id="aMonthySubscription" place="bottom" backgroundColor="#66AA47" effect="solid"> */}
<div className="box-notice" dangerouslySetInnerHTML={{__html:"A pre-pay one month gift subscription",}}/>
{/* </ReactTooltip> */}
            </div>
            <div className="plans-discount-label">
              <p>{plans.acf.shop.discount}</p>
            </div>
          </div>
        </div>
        <div className="plans">
          <div className="plans-label">
            <p id="plans-label-giftingplans">Discover Ireland Every Month</p>
          </div>
          {plans.acf.planfirst.planboxes.map((e, index) =>

 index !== 0 ? (
  
              <div
                className="box-discount"
                onClick={() => {
                  (e.offferproductid !== "" &&
                    window.location.href.indexOf("cw") !== -1) ||
                  window.location.href.indexOf("gift/") !== -1
                    ? goToCart(e.offferproductid)
                    : goToCart(e.productid);
                }}
              >
                <div
                  className="box"
                  onClick={() => {
                    (e.offferproductid !== "" &&
                      window.location.href.indexOf("cw") !== -1) ||
                    window.location.href.indexOf("gift/") !== -1
                      ? goToCart(e.offferproductid)
                      : goToCart(e.productid);
                  }}
                >
               <div className="extraDiv">
               {e.planboxBadge ? <img src={e.planboxBadge} className="ribon" alt="ribon"/> : ""

}
  
  <div
    className="box-plan-image"
    style={{
      backgroundImage: `url(${e.planboximg})`,
    }}
  >{
    e.boxpng ? <img src={`${e.boxpng}`} className="boxPng" alt="box" /> : ""
  }
    
  </div>
  <div className="cardText">
    <div
      className="sub-type"
      dangerouslySetInnerHTML={{ __html: e.month }}
    ></div>
    <p
      className="price-plans"
      dangerouslySetInnerHTML={{ __html: e.price }}
    ></p>
    { e.customtext && ( window.location.href.indexOf("cw") !== -1 ||
    window.location.href.indexOf("cw") !== -1 ? (
      <div
        className="extra-plans a"
        dangerouslySetInnerHTML={{
          __html: e.customtext,
        }}
      ></div>
    ) : (
      <div
        className="extra-plans a"
        dangerouslySetInnerHTML={{
          __html: e.customtext,
        }}
      ></div>
    ) )}
        {/* {index === 1 ?  <p className="extra-plans">We will send a personalized e-card on St. Patrick's Day to the gift recipient!</p> :""}  */}
        <div
    className="extra-plans"
      dangerouslySetInnerHTML={{ __html: e.offertext }}
    ></div>
        {  console.log("asdasdsd",e)}
    <button
      className="plans-sub-btn"
      formTarget="dummyFrame"
      onClick={() => {
        (e.offferproductid !== "" &&
          window.location.href.indexOf("cw") !== -1) ||
        window.location.href.indexOf("gift/") !== -1
          ? goToCart(e.offferproductid)
          : goToCart(e.productid);
      }}
    >
      <p
        dangerouslySetInnerHTML={{ __html: e.subscribebtn }}
      ></p>
    </button>
  </div>
               </div>
                  <iframe
                  title="dummyFrame"
                    name="dummyFrame"
                    style={{ display: "none", width: "0", height: "0" }}
                  ></iframe>

                
                  {/* <div
                    className="box-notice hide-on-mobile"
                    dangerouslySetInnerHTML={{ __html: e.notice }}
                    data-tip
                    data-for={`happyFace${index}`}
                  /> */}
{/* 
                  <ReactTooltip
                    id={`happyFace${index}`}
                    place="bottom"
                    backgroundColor="#66AA47"
                    effect="solid"
                  > */}
                    <div
                      className="box-notice"
                      dangerouslySetInnerHTML={{ __html: e.tooltip }}
                    />
                  {/* </ReactTooltip> */}
                </div>
                <div className="plans-discount-label">
                  <p>{e.discount}</p>
                </div>
              </div>
            ) : null
          )}
        </div>
      </div>
      <p className="satisfied">{plans.acf.planfirst.notice}</p>
      <Zoom delay={100} duration={200}>
        <div className="commingNext">
          <p id="border-paragraph">What's coming next?</p>
          <p id="nextEvent"> {form.acf.whats_coming_next_title}</p>
          <p id="nextEvent-description">{form.acf.whats_coming_next_text}</p>
        </div>
      </Zoom>

      <TrustBoxSlider />
      <Carousel items={items} active={0} />

      <MeetFounder plans={plans} />
      <JoinFacebookGroup plans={plans} />

      <Footer />
    </>
  );
}

export default GiftingPlans;
