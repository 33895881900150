import React, { useState } from "react";
import letters from "../../../assets/letters.png";
import { Link } from "react-router-dom";
import Hamburger from "hamburger-react";

const LettersNav = (props) => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
    document.body.style.overflow = showMenu ? "auto" : "hidden";
  };
  const closeMenu = () => {
    setShowMenu(false);
    document.body.style.overflow = "auto";
  };
  return (
    <div className={`lettersNav ${showMenu ? "showMenu" : ""}`}>
      <div className="letters-container letters-display">
        <div className="lettersLogo">
          <img src={letters} alt="Letters logo" />
        </div>
        <div className="lettersList">
          <ul>
            <li onClick={props.find}>How it works</li>
            <li onClick={props.find2}>About</li>
            <li onClick={props.find3}> Irish Shop</li>
            <li className="letters-sub" onClick={props.find3}>
              <p to="/form">Subscribe</p>
            </li>
          </ul>
        </div>
        <div className="hamburger" onClick={toggleMenu}>
        <Hamburger size={25} />
        </div>
      </div>
      {showMenu && (
        <div className="dropdown">
         <ul>
            <li onClick={props.find}>
              <p onClick={closeMenu}>
                How it works
              </p>
            </li>
            <li onClick={props.find2}>
              <p onClick={closeMenu}>
                About
              </p>
            </li>
            <li onClick={props.find3}>
              <p onClick={closeMenu}>
                Irish Shop
              </p>
            </li>
            <li className="letters-sub" onClick={props.find3}>
              <p onClick={closeMenu}>
                Subscribe
              </p>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default LettersNav;
