import React from "react";
import { Link } from "react-router-dom";
import "../../../Shared/Styles/PeekInside/peekInside.css";
import { HiArrowSmRight } from "react-icons/hi";

function InsideProducts({ inside, posts }) {
  return (
    <div className="peek-main">
      <Link to="/form">
        <p style={{margin:"0px", color:"#48874c",fontWeight:"bold"}}
          dangerouslySetInnerHTML={{
            __html: posts.acf.monthlythemes.beforeh2,
          }}
        ></p>
      </Link>
      <p
        className="main-title"
        dangerouslySetInnerHTML={{
          __html: posts.acf.monthlythemes.monthlyheading,
        }}
      ></p>

      <p
        className="main-paragraph"
        dangerouslySetInnerHTML={{
          __html: posts.acf.monthlythemes.monthlyparagraph1,
        }}
      ></p>
      <p
        className="main-paragraph"
        dangerouslySetInnerHTML={{
          __html: posts.acf.monthlythemes.monthlyparagraph2,
        }}
      ></p>
      <p
        className="main-paragraph"
        dangerouslySetInnerHTML={{
          __html: posts.acf.monthlythemes.monthlyparagraph3,
        }}
      ></p>
      <p
        className="main-paragraph"
        dangerouslySetInnerHTML={{
          __html: posts.acf.monthlythemes.monthlyparagraph4,
        }}
      ></p>
      <div className="posts">
        {inside.map((res) => {
          return (
            <div
              key={res}
              className="post"
              id={res.id}
              style={{
                backgroundImage:
                  "linear-gradient(to top, rgba(0, 0, 0, 0.45), rgba(255, 255, 255, 0.1)), url('" +
                  res.featured_image_url +
                  "')",
              }}
            >
              <Link to={"/" + res.slug}>
                <h1>{res.title.rendered}</h1>
                <p style={{display:"flex",width:"150px",justifyContent:"center",margin:"0px",padding:"8px 0px",borderRadius:"5px",    background:" rgb(72, 135, 76)",}}>
                  Peek Inside <HiArrowSmRight />
                </p>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default InsideProducts;
