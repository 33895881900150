import React, { useState } from "react";
import "../components/Shared/Styles/footer.css";
import logo from "../assets/logo.svg";
import fb from "../assets/Facebook.svg";
import ig from "../assets/Instagram.svg";
import pi from "../assets/Pintrest.svg";
import tt from "../assets/Tiktok.svg";
import tw from "../assets/Twitter.svg";
import emailarrow from "../assets/emailarrow.png";
import { Link } from "react-router-dom";
import LazyLoad from 'react-lazyload';


function Footer() {
  const [mailError, setMailError] = useState(true);
  const [message, setMessage] = useState(false);
  const [email, setEmail] = useState("");
  const validateEmail = (email) => {
    const emailRegex = new RegExp(/\S+@\S+\.\S+/);

    if (emailRegex.test(email) === false) {
      setMailError(false);
    } else {
      setMailError(true);
    }
  };
  var date = new Date();
  var year = date.getUTCFullYear();
  return (
    <div className="main-footer">
      <div className="footer-container">
        <div className="row">
          {/* Column1 */}
          <div className="col" id="col1">
            <Link to={window.location.href.indexOf("cw") !== -1 ? "/cw" : "/"}>
                <LazyLoad height={200}>
              <img src={logo} alt={"logo"} className="footer-logo" />
              </LazyLoad>
            </Link>

            <li>
              <p className="irish-paragraph">
                Irish-made treasures from the original Irish Subscription Box,
                loved by the thousands.
              </p>
            </li>
          </div>

          {/* Column2 */}
          <div className="col" id="col2">
            <h4>Quick Links</h4>
            <ul className="list-unstyled">
              <li>
                <Link
                  to={
                    window.location.href.indexOf("cw") !== -1
                      ? "/cw/peek-inside"
                      : "/peek-inside"
                  }
                >
                  Past Boxes
                </Link>{" "}
              </li>
             
              <li>
                <Link
                  to={
                    window.location.href.indexOf("cw") !== -1
                      ? "/cw/form"
                      : "/form"
                  }
                >
                  Subscribe Now
                </Link>
              </li>
              {/* <li>
                <a href="https://www.myirelandboxstore.com/" target="_blank">
                  Shop
                </a>
              </li> */}
              <li>
                <a
                  href="https://checkout.myirelandbox.com/account/auth/login?store=myirelandbox&redirect_url=https%3A%2F%2Fwww.myirelandbox.com%2Fsubscribe-now"
                  target="_blank"
                >
                  Log in
                </a>
              </li>
              <li>
                <Link
                  to="/blog"
                >
                 Blog
                </Link>{" "}
              </li>
              <li>
                <Link
                  to={
                    window.location.href.indexOf("cw") !== -1
                      ? "/cw/aboutus"
                      : "/aboutus"
                  }
                >
                  About Us
                </Link>
              </li>
              
            </ul>
          </div>

          {/* Column3 */}
          <div className="col" id="col3">
            <h4>Support</h4>
            <ul className="list-unstyled">
              <li>
                <Link
                  to={
                    window.location.href.indexOf("cw") !== -1
                      ? "/cw/faqs"
                      : "/faqs"
                  }
                >
                  FAQs
                </Link>
              </li>
              <li>
                <Link
                  to={
                    window.location.href.indexOf("cw") !== -1
                      ? "/cw/contact-us"
                      : "/contact-us"
                  }
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>

          {/* column 4 */}

          {/* Column5 */}
          <div className="col" id="col4">
            <p className="email-signup">
            Signup for exclusive deals, free Irish recipes, and personalized postcards from Katharine!
            </p>
            <ul className="list-unstyled">
              <div className="footer-subscribe">
                <form
                  action="https://cms.myirelandbox.com/wp-content/newslettertag.php"
                  method="POST"
                  className="footer-form"
                >
                  <input
                    className="email"
                    type="email"
                    placeholder="Enter your email"
                    name="email"
                    required
                    onChange={(e) => {
                      setEmail(e.target.value);
                      validateEmail(e.target.value);
                    }}
                  ></input>

<input
              name="fname"
              value={"n/a"}
              style={{ display: "none" }}
            />
            <input
              name="lname"
              value={"n/a"}
              style={{ display: "none" }}
            />
            <input
              name="attraction"
              value={"n/a"}
              style={{ display: "none" }}
            />
            <input
              name="connection"
              value={"n/a"}
              style={{ display: "none" }}
            />
             <input
              name="tag"
              value={"Newsletter"}
              style={{ display: "none" }}
            />
        

                  <iframe
                    name="footerEmail"
                    style={{ display: "none", width: "0", height: "0" }}
                  ></iframe>
                  <button
                    className="footer-submit-button"
                    formTarget="footerEmail"
                    onClick={() => {
                      if (mailError === true && email.length > 1) {
                        setMessage(true);
                      } else {
                        setMessage(false);
                      }
                    }}
                  >
                     <LazyLoad height={200}>
                    <img src={emailarrow} alt={"tt"}></img>
                    </LazyLoad>
                  </button>
                </form>
              </div>
              {!mailError ? (
                <p
                  style={{
                    color: "red",
                    fontSize: "12px",
                    textAlign: "left",
                    width: "300px",
                    position: "absolute",
                  }}
                >
                  Enter a valid email!
                </p>
              ) : null}
              {message === true && mailError === true ? (
                <p
                  style={{
                    color: "#1b6047",
                    fontSize: "13px",
                    textAlign: "left",
                    // width: "300px",
                    position: "absolute",
                  }}
                >
                  Thank you for subscribing, we can't wait to share with you our updates, deals and more! 😊 😊
                </p>
              ) : (
                ""
              )}
              <div>
                <ul className="socials">
                  <li>
                    <a
                      href="https://www.facebook.com/MyIrelandBox"
                      target="_blank"
                    >
                       <LazyLoad height={200}>
                      <img src={fb} alt={"fb"}></img>
                      </LazyLoad>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/myirelandbox/">
                    <LazyLoad height={200}>
                      <img src={ig} alt={"ig"}></img>
                      </LazyLoad>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/MyIrelandBox" target="_blank">
                    <LazyLoad height={200}>
                      <img src={tw} alt={"tw"}></img>
                      </LazyLoad>
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a
                      href="https://www.pinterest.ie/myirelandbox/"
                      target="_blank"
                    >
                       <LazyLoad height={200}>
                      <img src={pi} alt={"pi"}></img>
                      </LazyLoad>
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a
                      href="https://www.tiktok.com/@myirelandbox?"
                      target="_blank"
                    >
                      <LazyLoad height={200}>
                      <img src={tt} alt={"tt"}></img>
                      </LazyLoad>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="business-info">
                <ul>
                  <li>
                    <Link to="/">MIB & Co Ltd, </Link>
                  </li>
                  <li>
                    <Link to="/">t/a MyIrelandBox, Dingle Hub,</Link>
                  </li>
                  <li>
                    <Link to="/">Coleen Business Park,</Link>
                  </li>
                  <li>
                    <Link to="/">Dingle, Co Kerry, V92 P9NX</Link>
                  </li>
                </ul>
              </div>
            </ul>
          </div>
        </div>
        <hr className="hr" />
        <div className="row1">
          <ul className="footer-bottom">
            <li className="rights">{year} All Rights Reserved</li>
            <li>
              <Link
                to={
                  window.location.href.indexOf("cw") !== -1
                    ? "/cw/terms-of-services"
                    : "/terms-of-services"
                }
              >
                Terms of Service
              </Link>
            </li>
            <li>
              <Link
                to={
                  window.location.href.indexOf("cw") !== -1
                    ? "/cw/privacy-policy"
                    : "/privacy-policy"
                }
              >
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link
                to={
                  window.location.href.indexOf("cw") !== -1
                    ? "/cw/cookie-policy"
                    : "/cookie-policy"
                }
              >
                Cookie Policy
              </Link>
            </li>
            <li>
              <Link
                to={
                  window.location.href.indexOf("cw") !== -1
                    ? "/cw/shipping-costs"
                    : "/shipping-costs"
                }
              >
                Shipping Costs
              </Link>
            </li>
            <li>
              <Link
                to={
                  window.location.href.indexOf("cw") !== -1
                    ? "/cw/refund-policy"
                    : "/refund-policy"
                }
              >
                Refund Policy
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Footer;
