import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlinePlayCircle } from "react-icons/ai";
import kath2 from "../../../../assets/kath.mp4";
import tick from "../../../../assets/tick.svg";
import kath from "../../../../assets/katttt.png";
import prof from "../../../../assets/kathprofile.png";
const Member = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openPopup = () => {
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };
  return (
    <div className="member-bck">
      <div className="member-container">
        {isOpen && (
          <div className="popup-overlay">
            <div className="popup">
              <div className="popup-content">
              <iframe width="560" height="315" src="https://youtube.com/embed/T8CsIWIenBw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                <div className="popUp-description">
                  <div>
                    <img src={prof} alt="" />
                    <p className="kath">Katharine Keane Barrett</p>
                  </div>
                  <p className="popUp-scroll">
                    Big hello (Dia Dhuit in Gaelic!) to you! I also want to tell
                    you about MyIrelandBox and why I began, in 2013, to send a
                    box full of Irish-made surprises, hand-picked by me, to
                    people that loved Ireland and maybe missed it. Well, it
                    began really when I was expecting my first child, Kate (now
                    10 years old!) and I was working as a lawyer and at the
                    weekends meeting many people from the USA and Canada that
                    were in Dingle town on vacation and expressing how sad they
                    were to be going home and how much they would miss Ireland.
                    It occurred to me that there must be so many people abroad
                    that had Irish roots and that would just love to visit
                    Ireland but for some reason or other, perhaps could not. So,
                    I thought that it would be a lovely idea to bring Ireland to
                    them. So, I established MyIrelandBox and decided that I
                    would send everyone that wanted one, a MyIrelandBox full of
                    the Irish made products that I loved - books that would
                    transport them to Ireland, Celtic jewellery that would put a
                    smile to everyone's face, beautiful and useful Irish
                    hand-made homewares made by our most talented craftspeople,
                    Gaelic words and phrases to spread the joy of our language
                    and much more. I began writing a letter to everyone and I
                    continue that every month and I continue that every month,
                    now within a Booklet from Ireland, and I love it! When you
                    have a passion for Ireland, Irish tradition and culture - it
                    becomes a mission to share it with those who also love
                    everything about Ireland. I am from Dingle, Co. Kerry, where
                    my ancestors are also from. I have a passion for Gaelic,
                    Irish music, Irish poetry and writing, Irish craft and the
                    beautiful Celtic jewelry that is made in Ireland and
                    especially the traditions and culture of our forefathers and
                    those that carry them on today. I love to share it with
                    MyIrelandBox members every month though a curation from my
                    heart, in to the homes of MyIrelandBox members.{" "}
                  </p>

                  <div className="popUp-buttons">
                    <Link to="/form" alt="#">
                      Subscribe Today
                    </Link>
                    <Link to="/gifting-form" alt="#">
                      View More
                    </Link>
                  </div>
                </div>
                <button onClick={closePopup}>X</button>
              </div>
            </div>
          </div>
        )}
        <div className="play-popUp" onClick={openPopup}>
          <img src={kath} />

          <div className="play-content">
            
            <h1>Katharine reveals the next three themes!

            </h1>
            <p className="hoverable">
              <AiOutlinePlayCircle />
              Play Now
            </p>
          </div>
        </div>
        <div className="memebr-display">
          <h1>Member Perks</h1>
          <ul>
            {/* <li>
              
              <img src={tick} />
              <b>FREE Irish handmade jewellery</b> (up to $70 in value)
            </li> */}
            <li>
              <img src={tick} />
              Access to the monthly <b> “$350+ Golden Shamrock prize”</b>
            </li>
            <li>
              <img src={tick} />
              Competitive, member-only pricing
            </li>
            <li>
              <img src={tick} />
              24/7 access to your active community on Facebook
            </li>
          </ul>

          <h2>
            <b>Starting at $41.65</b>
          </h2>
          <p>+ Tracked Shipping </p>
          <Link
            to={
              window.location.href.includes("christmas-ecard")
                ? "/gifting-form"
                : "/form"
            }
          >
            {" "}
            Yes, I want a MyIrelandBox!
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Member;
