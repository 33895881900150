import React from "react";
import { Link } from "react-router-dom";

import pers from "../../../../assets/pers.png";
const Personalized = () => {
    return(
        <div className="personalizedBck">
            <div className="persDes">
                <span>Popular Gift Option</span>
                <h2>A Personalized E-Card from Ireland!</h2>
              <p>
                 It’s no secret, everyone loves the feeling when the
                 postman delivers gifts to us. Knowing that a box of surprises
                 from Ireland is on the way for the next three months, well,
                 that is a recipe for happiness!
              </p>
           <h4>How it works?</h4>
            <p>
               Start by gifting a 3-month MyIrelandBox subscription. Within
               the next 24 hours, we will email you asking about the recipients
               information. Next, we will personalize the E-card based on that
               information, and send it on Christmas Eve! 
             </p>
             <Link to={window.location.href.includes("christmas-ecard") ? "/gifting-form" :"/form"} >Get Started</Link>
            </div>
            <div className="persImage">
                <img src={pers} alt=""/>
            </div>
        </div>
    )
}

export default Personalized ;