import React, { useEffect, useState } from "react";
import "../../Styles/Homepage/firstbanner.css";
import { Link } from "react-router-dom";
import dream from "../../../../assets/theme.png";
import Modal from 'react-modal';
import LazyLoad from 'react-lazyload';



function FirstBanner({ posts }) {
  const [days, setDays] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);


  var date = new Date();

  var countDown = new Date(
    posts.acf.first_section.countdown.substring(
      0,
      posts.acf.first_section.countdown.length - 1
    )
  ).getTime();

  function isMobile() {
    return window.innerWidth <= 999; 
  }
  

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     var now = new Date().getTime();
  //     var timeleft = countDown - now;
  //     var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
  //     if (days <= 9) {
  //       days = "0" + days;
  //     }

  //     var hours = Math.floor(
  //       (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  //     );
  //     if (hours <= 9) {
  //       hours = "0" + hours;
  //     }

  //     var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
  //     if (minutes <= 9) {
  //       minutes = "0" + minutes;
  //     }

  //     var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

  //     setSeconds(seconds);
  //     setDays(days);
  //     setHours(hours);
  //     setMinutes(minutes);
  //   }, 1000);
  //   return () => clearInterval(interval);
  // }, []);
  

  return (
    <>
      {/*FIRST SECTION DESKTOP*/}
      <div className="first-section"
        style={
          {
                backgroundImage: `url(${posts.acf.first_section.banner})`,
        }}
      >
        <div className="section-container">
          <div className="first-section-content">
          <LazyLoad height={200}>
            <img className="banner-mobile" src={posts.acf.first_section.banner_mobile} alt="bannermobile"></img>
            </LazyLoad>
            <h1
              className="bannerTitle"
              dangerouslySetInnerHTML={{
                __html: posts.acf.first_section.main_title,
              }}
            ></h1>
            <p
              className="bannerParagraph"
              dangerouslySetInnerHTML={{
                __html: posts.acf.first_section.firstbanner_text2,
              }}
            ></p>
            <div className="bannerBtns">
              <Link
                to={
                  window.location.href.indexOf("cw") !== -1
                    ? "/cw/form"
                    : "/form"
                }
                className="button1 "
              >
Subscribe Now              </Link>
              <Link
                to={
                  window.location.href.indexOf("cw") !== -1
                    ? "/cw/gifting-form"
                    : "/gifting-form"
                }
                className="button2 "
              >
              Gift a MyIrelandBox
              </Link>
            </div>
           
            <p className="notice">
            {posts.acf.first_section.notice_mobile}
            </p>
            <Link   to={
                  window.location.href.indexOf("cw") !== -1
                    ? "/cw/gifting-form"
                    : "/gifting-form"
                } className="dream">
        <p>What Ships Next?</p>
      
        <p
             
              dangerouslySetInnerHTML={{
                __html: posts.acf.first_section.shipping_note,
              }}
            ></p>
        <img src= {posts.acf.first_section.shipsnext} alt="#"/>
       </Link>
        </div>
          </div>
        
       
      </div>

    </>
  );
}

export default FirstBanner;
