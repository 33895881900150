import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { HiArrowSmRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import LazyLoad from 'react-lazyload';
//styles
import "../../components/Shared/Styles/singleBox.css";

//components
import Navbar from "../Navbar";
import Footer from "../Footer";
import ReadytoJoin from "../Shared/Pages/PeekInside/ReadytoJoin";
import Loader from "../utilities/Loader";

function SingleBox({ posts }) {
  const [state, setState] = useState({
    posts: [],
    isLoaded: false,
  });
  const [otherData, setOtherData] = useState({
    otherPost: [],
    isLoaded: false,
  });
  if (state.posts.length > 0)
    if (window.location.pathname.substring(1) !== state.posts[0].slug) {
      window.location.reload();
    }
  useEffect(() => {
    axios
      .get(
        "https://cms.myirelandbox.com/wp-json/wp/v2/posts?slug=" +
          window.location.pathname.substring(1)
      )
      .then((res) => {
        setState({
          ...state,
          posts: res.data,
          isLoaded: true,
        });
        axios
          .get(
            "https://cms.myirelandbox.com/wp-json/wp/v2/posts?per_page=9&exclude=" +
              res.data[0].id
          )
          .then((res) => {
            setOtherData({
              otherPost: res.data,
              isLoaded: true,
            });
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }, []);
  if (state.isLoaded === false && otherData.isLoaded === false)
    return <Loader />;
  else {
    return (
      <div>
        <Navbar posts={posts} />

        <div style={{ padding: "30px 0 0px" }}>
          {" "}
          {ReactHtmlParser(state.posts[0].content.rendered)}{" "}
        </div>
        {/* <InsideProducts inside={otherData.otherPost} /> */}
        <div className="peek-main">
          <p id="main-subtitle" style={{color:"#48874c ",fontWeight:"600"}}>Surprise Yourself Every Month</p>
          <p className="main-title">MyIrelandBox Monthly Themes</p>
          <p className="main-paragraph" id="single-box-old-boxes">
            Want to feel like you've brought Ireland into your heart and home?{" "}
            <br></br>Find out what you can expect to receive over the next few
            months in your MyIrelandBox.<br></br> February ~ Celtic Love / March
            ~ St. Patrick's Day<br></br> Each MyIrelandBox is full of Irish-made
            surprises that we carefully select from the best of Ireland's makers
            that fit in perfectly with the unique theme that we have chosen that
            month. <br></br>Our prices start from $41.65 per MyIrelandBox
            (12-month plan).
            <br></br> Each MyIrelandBox is always worth between RRP $70 and $90
            USD. Every month has a new theme so that you discover Ireland in a
            new way each time you open your MyIrelandBox.
          </p>
          <div className="posts">
            {otherData.otherPost.map((res) => {
              return (
                <div
                  key={res}
                  className="post"
                  id={res.id}
                  style={{
                    backgroundImage:
                      "linear-gradient(to top, rgba(0, 0, 0, 0.45), rgba(255, 255, 255, 0.1)), url('" +
                      res.featured_image_url +
                      "')",
                  }}
                >
                  <Link to={"/" + res.slug}>
                    <h1>{res.title.rendered}</h1>
                    <p style={{display:"flex",width:"150px",justifyContent:"center",margin:"0px",padding:"8px 0px",borderRadius:"5px",    background:" rgb(72, 135, 76)",}}>
                      Peek Inside <HiArrowSmRight />
                    </p>
                  </Link>
                </div>
              );
            })}
            <div></div>
          </div>
        </div>
        <LazyLoad height={200}>
        <ReadytoJoin />
        </LazyLoad>

        <Footer />
      </div>
    );
  }
}

export default SingleBox;
