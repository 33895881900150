import React, { useEffect, useState } from "react";
import LazyLoad from "react-lazyload";
import Modal from 'react-modal';
import { Link } from "react-router-dom";

import all from "../../../../assets/priz.png";

const Craft = ({ posts }) => {

    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
      setIsOpen(true);
    }
  
    function afterOpenModal() {
      // references are now sync'd and can be accessed.
      // document.getElementById("body").style.opacity = "0.1";
      document.getElementById("modal-content2").classList.add("modal-fade-in");
    }
  
    function closeModal() {
      setIsOpen(false);
    }
  
  
  
  
  
    return  (
        <div className="third-section">

        <div className="nextship">
          <div className="ship-left">
            <p className="txt-head">The Golden Shamrock Prize</p>
            <h1 className="handbag-title" style={{fontFamily:"The new Elegance"}}>The Timeless & Elegant Silver Pendant from Niamh Utch
</h1>
            <div className="ship-right mobile-ship">
          <LazyLoad height={200}>
            <img src={all} className="txt-img" />
            </LazyLoad>
          </div>
            <p className="txt-paragraph">Introducing our next Golden Shamrock Prize: the exquisite Silver Pendant, crafted with a captivating 10mm mint quartz that echoes the serene waters surrounding our beloved islands. Paired with an 18" sterling silver chain, this piece is a testament to the natural beauty of Ireland. Lovingly made by one of our favorite local jewelers, Niamh Utch, this pendant beautifully blends timeless elegance with a touch of island-inspired charm, making it indeed one of our favorite Golden Shamrock prizes yet!
            </p>
             <div className="handbag-display">
             <Link to={window.location.href.includes("christmas-ecard") ? "/gifting-form" :"/form"}  >{window.location.href.includes("christmas-ecard") ? "Gift to Enter Draw" :"Subscribe to Enter Draw"} </Link>
             <p onClick={openModal} className="hoverable">See Rules</p>
             </div>
          </div>
          <div className="ship-right desktop-ship">
          <LazyLoad height={200}>
            <img src={all} className="txt-img" />
            </LazyLoad>
          </div>

          <div className="thebade" onClick={openModal} >
        <LazyLoad height={200}>
             <img src={posts.acf.modalBadge} id="badge-btn-image" alt="badgeimage"></img>
        </LazyLoad>
      </div>
        </div>

            
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        id="modal-content2"
        contentLabel="Example Modal"
      >
      <div className="draw-offer-homepage" >
        <div onClick={closeModal} id="closemodal-badge2">X</div>
            <LazyLoad height={200}>
        <img id="badge3" src={posts.acf.modalBadge} alt="badgeimage
        "></img>
        </LazyLoad>
          <p   dangerouslySetInnerHTML={{
            __html: posts.acf.modalcontent2,
          }}></p>
          <Link to="/form">
        <button className="modalbtn " onClick={closeModal}>
          Continue to Enter
        </button>
        </Link>
        </div>
      </Modal>

     </div>
    )
}

export default Craft;