import React from "react";
import { Link } from "react-router-dom";

function ReadytoJoin() {
  return (
    <div className="join" id="join">
      <h2>Ready to Join?</h2>
      <p>
        Subscribe to MyIrelandBox and experience <br /> Ireland every month!
      </p>
      <div className="join_buttons">
        <Link to="/form">
          <button className="join_button">Join MyIrelandBox</button>
        </Link>
        <Link to="/gifting-form">
          <button className="gift_button">Gift the Next Box</button>
        </Link>
      </div>
    </div>
  );
}

export default ReadytoJoin;
