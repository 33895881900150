import React from "react";
import Footer from "../../Footer";
import Navbar from "../../Navbar";
import MeetFounder from "./MeetFounder/MeetFounder";
import JoinFacebookGroup from "./JoinFacebookGroup/JoinFacebookGroup";
import TrustBoxSlider from "../Form/TrustBoxSlider";
import Zoom from "react-reveal/Zoom"; // Importing Zoom effect
import LazyLoad from 'react-lazyload';
import sham from "../../../assets/gra.png"

// CSS
import "../Styles/plans.css";

// IMAGES
import Carousel from "./PlansCarousel/Carousel";

import "../Plans/PlansCarousel/plansCarousel.scss";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

function Plans({ posts, form, pastBoxes, plans }) {
  var items = [];
  for (var i = 0; i < pastBoxes.length; i++) {
    items.push(pastBoxes[i]);
  }

  const goToCart = (productId) => {
    
    window.open(
      `https://checkout.myirelandbox.com/checkout/buy/${productId}`,
      "_self"
    );
  };

  return (
    <>
      <Navbar posts={posts} />

      <h1
        className="plans-title"
        dangerouslySetInnerHTML={{ __html: plans.acf.planfirst.mainheading }}
      ></h1>

      <h1 className="gifting-plans-link">
        Are you insterested in gifting a MyIrelandBox? <br></br>
        <Link
          to={
            window.location.href.indexOf("cw") !== -1
              ? "/cw/gifting-plans"
              : "/gifting-plans"
          }
        >
          Please follow link here!
        </Link>
      </h1>
      <div className="products">
        <div className="shop-product-border">
          <div className="shop-product-label">
            <p id="shop-product-label-paragraph">Try MyIrelandBox</p>
          </div>

          <div
            className="box-discount"
            onClick={() => {
              goToCart(plans.acf.shop.productid);
            }}
          >
            <div
              className="box box-shop"
              onClick={() => {
                goToCart(plans.acf.shop.productid);
              }}
            >
              <div className="extraDiv">

              {plans.acf.shop.planboxBadge ?  
              <LazyLoad height={200}>
              <img src={plans.acf.shop.planboxBadge} className="ribon" alt="planboxbadge" />
              </LazyLoad>
              : " "}
              <div
                className="box-plan-image"
                style={{
                  backgroundImage: `url(${plans.acf.shop.planboximg})`,
                }}
              > <LazyLoad height={200}>
                <img src={`${plans.acf.shop.shopboxpng}`} className="boxPng"  alt="planboxbadge" />
                </LazyLoad>
              </div>
              <div className="cardText">
                <div
                  className="sub-type"
                  dangerouslySetInnerHTML={{ __html: plans.acf.shop.month }}
                ></div>
                <p
                  className="price-plans"
                  dangerouslySetInnerHTML={{ __html: plans.acf.shop.price }}
                ></p>
                <div
                  className="extra-plans"
                  dangerouslySetInnerHTML={{
                    __html: plans.acf.shop.customtext,
                  }}
                ></div>
                <button
                  className="plans-sub-btn"
                  formTarget="dummyFrame"
                  onClick={() => {
                    goToCart(plans.acf.shop.productid);
                  }}
                >
                  <p
                    dangerouslySetInnerHTML={{
                      __html: plans.acf.shop.subscribebtn,
                    }}
                  ></p>
                </button>
              </div>
              </div>
              {/* <div className="box-notice hide-on-mobile " dangerouslySetInnerHTML={{__html: "<p>Subscription Details</p>",}} data-tip data-for="aMonthySubscription" /> */}

                {/* <ReactTooltip id="aMonthySubscription" place="bottom" backgroundColor="#66AA47" effect="solid"> */}
                  <div className="box-notice" dangerouslySetInnerHTML={{__html:"The monthly subscription is billed every month and can be cancelled anytime.",}}/>
                {/* </ReactTooltip> */}
            </div>
            <div className="plans-discount-label">
              <p>{plans.acf.shop.discount}</p>
            </div>
          </div>
        </div>
        <div className="plans">
          <div className="plans-label">
            <p id="plans-label-paragraph">Discover Ireland Every Month</p>
          </div>
          {plans.acf.planfirst.planboxes.map((e, index) =>
            index !== 0 ? (
              <div
                className="box-discount"
                onClick={() => {
                  (e.offerproductid !== "" &&
                    window.location.href.indexOf("cw") !== -1) ||
                  window.location.href.indexOf("gift/") !== -1
                    ? goToCart(e.offerproductid)
                    : goToCart(e.productid);
                }}
              >
                <div
                  className="box"
                  onClick={() => {
                    (e.offerproductid !== "" &&
                      window.location.href.indexOf("cw") !== -1) ||
                    window.location.href.indexOf("gift/") !== -1
                      ? goToCart(e.offerproductid)
                      : goToCart(e.productid);
                  }}
                >
                  
                 <div className="extraDiv">
                 {e.planboxBadge  ?
                   <LazyLoad height={200}>
                  <img src={e.planboxBadge} className="ribon"  alt="planboxbadge" /> </LazyLoad>: ""

                }
                <div className={` ${index === 1 ? "boxBorder" :"fullWidth"}`}>
                  <div
                    className={`box-plan-image ${index === 1 ? "imageBorder" :""}`}
                    style={{
                      backgroundImage: `url(${e.planboximg})`,
                    }}
                  >
                    {
e.boxpng  ?  <img src={`${e.boxpng}`} className="boxPng"  alt="planboxbadge" /> : ""

                    }
                
                  </div>
                  {index === 1 ? 
                  <div className="silverPrice">
                      <p>Included: Hand-made Claddagh Necklace</p>
                    <img src={sham} alt="price"/>
                  </div> :""}
                  </div>
                  <div className="cardText">
                    <div
                      className="sub-type"
                      dangerouslySetInnerHTML={{ __html: e.month }}
                    ></div>
                    <p
                      className="price-plans"
                      dangerouslySetInnerHTML={{ __html: e.price }}
                    ></p>
                    {window.location.href.indexOf("cw") !== -1 ||
                    window.location.href.indexOf("gift") !== -1 ? (
                      <div
                        className="extra-plans"
                        dangerouslySetInnerHTML={{ __html: e.offertext }}
                      ></div>
                    ) : (
                      <div
                        className="extra-plans"
                        dangerouslySetInnerHTML={{ __html: e.customtext }}
                      ></div>
                    )}
                    <button
                      className="plans-sub-btn"
                      formTarget="dummyFrame"
                      onClick={() => {
                        (e.offerproductid !== "" &&
                          window.location.href.indexOf("cw") !== -1) ||
                        window.location.href.indexOf("gift/") !== -1
                          ? goToCart(e.offerproductid)
                          : goToCart(e.productid);
                      }}
                    >
                      <p
                        dangerouslySetInnerHTML={{ __html: e.subscribebtn }}
                      ></p>
                    </button>
                    <iframe
                    title="dummyFrame"
                      name="dummyFrame"
                      style={{ display: "none", width: "0", height: "0" }}
                    ></iframe>
                  </div>
                 </div>

                  {/* <div
                    className="box-notice show-on-mobile"
                    dangerouslySetInnerHTML={{ __html: e.tooltip }}
                  ></div> */}
                  {/* <div
                    className="box-notice hide-on-mobile"
                    dangerouslySetInnerHTML={{ __html: e.notice }}
                    data-tip
                    data-for={`happyFace${index}`}
                  /> */}
{/* 
                  <ReactTooltip
                    id={`happyFace${index}`}
                    place="bottom"
                    // type="dark"
                    backgroundColor="#66AA47"
                    effect="solid"
                    // textColor="white"
                  > */}
                    <div
                      className="box-notice"
                      dangerouslySetInnerHTML={{ __html: e.tooltip }}
                    />
                  {/* </ReactTooltip> */}
                </div>
                <div className="plans-discount-label">
                  <p>{e.discount}</p>
                </div>
              </div>
            ) : null
          )}
        </div>
      </div>
      <p className="satisfied">{plans.acf.planfirst.notice}</p>
      <Zoom delay={100} duration={200}>
        <div className="commingNext">
          <p id="border-paragraph">What's coming next?</p>
          <p id="nextEvent"> {form.acf.whats_coming_next_title}</p>
          <p id="nextEvent-description">{form.acf.whats_coming_next_text}</p>
        </div>
      </Zoom>

      <TrustBoxSlider />
      <Carousel items={items} active={0} />

      <MeetFounder plans={plans} />
      <JoinFacebookGroup plans={plans} />

      <Footer />
    </>
  );
}

export default Plans;
