import React, { useRef, useState } from 'react';
import HTMLFlipBook from 'react-pageflip';import { Link } from "react-router-dom";
import p1 from "../../assets/P-1.jpg";
import p2 from "../../assets/P-2.jpg";
import p3 from "../../assets/P-3.jpg";
import p4 from "../../assets/P-4.jpg";
import trust from "../../assets/tr.svg"

import Lottie from "react-lottie-player";
import swipe from "../../assets/swipe.json"
const Flipbook = () => {
  const flipBookRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(0);

  const totalPages = 3; // Total number of pages

  const goToNextPage = () => {
    if (flipBookRef.current && currentPage < totalPages - 1) {
      flipBookRef.current.pageFlip().flipNext();
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const goToPrevPage = () => {
    if (flipBookRef.current && currentPage > 0) {
      flipBookRef.current.pageFlip().flipPrev();
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  return (
        <>
           <div className='desPage'>
 <div className="flipbook-container">
     
     <HTMLFlipBook 
       ref={flipBookRef}
       className="flipbook"
       width={450} 
       height={650}
       showCover={false}
     >
       <div className="singlePage ">
               <div className='contentPage'>
               <h2>A themed booklet compiled by Katharine for our treasured members every month!</h2>
      
         <h3>Message from Katharine:</h3>
         <p>The 25 to 35 page booklet that I write every month for our treasured members has become a firm favourite inclusion in each MyIrelandBox. It brings me so much joy to go the extra miles to write this for everyone.<br/><br/>
We go on walks together, we visit ancient monuments and holy wells and fairy forts, learn all about nature in Ireland on that month and indeed our ancient traditions during that month. I include recipes and meet the makers of the surprises that are included. The world of folklore is explored, we learn some Irish … and so much more.<br/><br/>
It is a joy to write it and I’m especially happy that our members enjoy it so much.</p>
         <h3>Comment from member: PattI l. </h3>
         <p>"The monthly booklet is such a treat. I have learned so much about the ancestral home of my maternal grandparents. I think My Ireland Box has enriched my life and helped me to discover my Irish soul."</p>
        <div className='pageTrust'>
        <p>Verified review from</p>
        <img src={trust}/>
        </div>

        <div className="page-animation">
             <Lottie
               loop
               animationData={swipe}
               play
               style={{ width: 150, height: 150 }}
             />
           </div>
         
               </div>
       </div>
       <div className="page">
         <img src={p1} alt="Page 2" />
       </div>
       <div className="page">
         <img src={p2} alt="Page 3" />
       </div>
       <div className="page">
         <img src={p3} alt="Page 4" />
       </div>
       <div className="page">
         <Link to="/form">
           <img src={p4} alt="Page 5" />
         </Link>
       </div>
       <div className="singlePage"></div>
     </HTMLFlipBook>
     {/* <div className="controls">
       <button onClick={goToPrevPage}>Prev</button>

       <button onClick={goToNextPage}>Next</button>
     </div> */}
     <div className='swipe-controls'>
       <p>Swipe The Booklet</p>
       <p>To Get a Preview</p>
     </div>
   </div>
      </div>
      <div className='mobPage'>
         <div className="flipbook-container">
     
     <HTMLFlipBook 
       ref={flipBookRef}
       className="flipbook"
       width={300} 
       height={500}
       showCover={false}
     >
     
       <div className="page">
         <img src={p1} alt="Page 2" />
       </div>
       <div className="page">
         <img src={p2} alt="Page 3" />
       </div>
       <div className="page">
         <img src={p3} alt="Page 4" />
       </div>
       <div className="page">
         <Link to="/form">
           <img src={p4} alt="Page 5" />
         </Link>
       </div>
    
     </HTMLFlipBook>
   
     <div className='swipe-controls'>
       <p>Swipe The Booklet</p>
       <p>To Get a Preview</p>
     </div>
   </div>
      </div></>
  );
};

export default Flipbook;